<template>
  <div style="max-width: 1200px;margin: 0 auto;padding:20px;text-align: center">
    <h2>Доставка</h2>
    <ul class="my-ul">
      <li class="card my-li" style="padding: 20px">Стоимость доставки по городу - 1000 р.</li><br>
      <li class="card my-li" style="padding: 20px">Стоимость доставки в регионы - 1000 р.</li><br>
      <li class="card my-li" style="padding: 20px">Время доставки по городу max - 60 мин.</li>
    </ul>
    <h2>Оплата</h2>
    <ul class="my-ul">
      <li class="card my-li" style="padding: 20px">Оплата картами Visa / Mastercard</li><br>
      <li class="card my-li" style="padding: 20px">Halyk Bank на карту: 4405 6398 6427 2248</li><br>
      <li class="card my-li" style="padding: 20px">Kaspi Bank на карту: 4400 4301 7508 6542</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "Delivery"
}
</script>

<style scoped>

</style>